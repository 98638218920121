import { __ConversationTeam, __ConversationAccount, AccountId } from "@ollie-sports/models";
import _ from "lodash";
import * as express from "express";

import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

export async function conversation__server__updateAccountParticipants(
  p:
    | {
        selfAccountId: string;
        accountIdToRemove: string;
        conversationId: string;
        type: "removeOther";
      }
    | {
        selfAccountId: string;
        conversationId: string;
        type: "removeSelf";
      }
    | {
        selfAccountId: string;
        conversationId: string;
        accountIdsToAdd: string[];
        type: "addAccounts";
      }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Conversation.update({
    id: p.conversationId,
    doc: {
      accounts:
        p.type === "removeSelf"
          ? { [p.selfAccountId]: h._MagicDeleteValue }
          : p.type === "removeOther"
          ? { [p.accountIdToRemove]: h._MagicDeleteValue }
          : p.accountIdsToAdd.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {} as Record<string, true>)
    }
  });
  // SERVER_ONLY_TOGGLE
}

conversation__server__updateAccountParticipants.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const convo = await h.Conversation.getDoc(r.body.conversationId);
  if (convo?.conversationType !== "accounts" || !convo?.accounts[r.body.selfAccountId]) {
    throw new Error("May only update participants to your own accounts!");
  }
};

// i18n certified - complete
