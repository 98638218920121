import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function conversation__client__getConversationSubscription(p: { conversationId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.Conversation.docSubscription(p.conversationId));
}

// i18n certified - complete
