import _ from "lodash";
import {
  canAccessSquadConversation,
  isSquadConversation,
  sortAndFilterConversationsForUser
} from "../../compute/conversation.compute";
import { getUniversalHelpers } from "../../helpers";
import {
  CONVERSATION_TYPES,
  Conversation,
  OrgId,
  PlayerBundle__AccountType,
  TEAM_CONVERSATION_TYPES,
  Team,
  TeamId,
  __ConversationAccount,
  __ConversationOrg,
  __ConversationTeam
} from "@ollie-sports/models";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { getListOfAccountIdsForOrgChatChannel } from "../../compute";

export async function fetchAllConversationsForUser(p: {
  selfAccountId: string;
  teamIds: TeamId[];
  orgIds: OrgId[];
  playerIdMap: { playerId: string; type: PlayerBundle__AccountType }[];
  includeArchived: boolean;
  excludedTypes?: { [key in CONVERSATION_TYPES]?: true };
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const dedupedOrgIds = _.uniq(p.orgIds);

  const [teamConvos, orgConvos, userOrgs, userTeams, accountConvos, accountPrivate] = await Promise.all([
    p.excludedTypes?.team
      ? ([] as __ConversationTeam[])
      : h.Conversation.multiQuery({
          queries: p.teamIds.map(tid => {
            const query: SimpleQuery<Conversation> = {
              where: [{ teamId: ["==", tid] }, { conversationType: ["==", CONVERSATION_TYPES.team] }]
            };

            return query;
          })
        }).then(a => a.docs),
    p.excludedTypes?.org
      ? ([] as __ConversationOrg[])
      : h.Conversation.multiQuery({
          queries: dedupedOrgIds.map(oid => ({ where: [{ orgId: ["==", oid] }] }))
        }).then(a => a.docs as __ConversationOrg[]),
    h.Org.getDocs(dedupedOrgIds).then(a => _.compact(a)),
    h.Team.getDocs(p.teamIds).then(a => _.compact(a)),
    p.excludedTypes?.accounts
      ? ([] as __ConversationAccount[])
      : h.Conversation.query({
          where: [{ accounts: { [p.selfAccountId]: ["==", true] } }, { conversationType: ["==", CONVERSATION_TYPES.accounts] }]
        }).then(a => a.docs),
    h.AccountPrivate.getDoc(p.selfAccountId)
  ]);

  if (userTeams.find(t => !t.accounts[p.selfAccountId])) {
    throw new Error("Tried to access invalid team!");
  }

  if (!accountPrivate) {
    throw new Error("Unable to find account private!");
  }

  const teamAndAccountConvos = [...teamConvos, ...accountConvos];

  let conversations = _(teamAndAccountConvos)
    .concat(
      orgConvos.filter(orgConvo => {
        const org = userOrgs.find(a => a.id === orgConvo.orgId);
        return getListOfAccountIdsForOrgChatChannel({
          org: org!,
          orgTeams: userTeams.filter(t => t.orgId === org!.id),
          orgFilters: orgConvo.orgFilters
        }).includes(p.selfAccountId);
      })
    )
    .filter(c => {
      if (c.conversationType === CONVERSATION_TYPES.accounts) {
        return true;
      } else if (c.conversationType === CONVERSATION_TYPES.org) {
        // We filtered by accountId up above
        return true;
      } else if (c.conversationType === CONVERSATION_TYPES.team) {
        let team: Team = userTeams.filter(t => t.id === c.teamId)[0];
        if (c.teamConversationType === TEAM_CONVERSATION_TYPES.all || c.teamConversationType === TEAM_CONVERSATION_TYPES.alerts) {
          return true;
        } else if (c.conversationType === CONVERSATION_TYPES.team && isSquadConversation(c) && team.enabledFeatures?.squads) {
          const canAccessConversation = canAccessSquadConversation({
            accountId: p.selfAccountId,
            playerIdMap: p.playerIdMap,
            team,
            teamConversationType: c.teamConversationType
          });
          if (canAccessConversation) {
            return true;
          }
        } else if (c.teamConversationType === TEAM_CONVERSATION_TYPES.players && team.accounts[p.selfAccountId]?.roles?.athlete) {
          return true;
        } else if (c.teamConversationType === TEAM_CONVERSATION_TYPES.staff && team.accounts[p.selfAccountId]?.roles?.staff) {
          return true;
        } else if (
          c.teamConversationType === TEAM_CONVERSATION_TYPES.staff_guardians &&
          (team.accounts[p.selfAccountId]?.roles?.staff || team.accounts[p.selfAccountId]?.roles?.guardian)
        ) {
          return true;
        } else if (
          c.teamConversationType === TEAM_CONVERSATION_TYPES.team &&
          (team.accounts[p.selfAccountId]?.roles?.staff || team.accounts[p.selfAccountId]?.roles?.athlete)
        ) {
          return true;
        }
      }
      return false;
    })
    .value();

  conversations = sortAndFilterConversationsForUser(conversations, accountPrivate, p.includeArchived);

  return { conversations: conversations, userOrgs, userTeams };
}
