import { __ConversationAccount, CONVERSATION_TYPES } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function conversation__server__createAccountConversation(p: {
  selfAccountId: string;
  accounts: __ConversationAccount["accounts"];
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const newId = h.Conversation.generateId();
  const now = Date.now();

  const convo: __ConversationAccount = {
    conversationType: CONVERSATION_TYPES.accounts,
    id: newId,
    updatedAtMS: now,
    createdAtMS: now,
    accounts: p.accounts
  };

  await h.Conversation.add({ doc: convo });

  return newId;
  // SERVER_ONLY_TOGGLE
}

conversation__server__createAccountConversation.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
