import { Conversation, OrgId, TeamId, __ConversationOrg, __ConversationTeam, __ConversationAccount } from "@ollie-sports/models";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import _ from "lodash";
import { fetchAllConversationsForUser } from "./conversationMisc";

export async function conversation__server__getAllTeamAndOrgConvosForAccount(p: {
  selfAccountId: string;
  teamIds: TeamId[];
  orgIds: OrgId[];
}): Promise<Conversation[]> {
  // SERVER_ONLY_TOGGLE

  let { conversations } = await fetchAllConversationsForUser({
    orgIds: p.orgIds,
    playerIdMap: [],
    selfAccountId: p.selfAccountId,
    teamIds: p.teamIds,
    excludedTypes: { accounts: true },
    includeArchived: true
  });

  return conversations;

  // SERVER_ONLY_TOGGLE
}

conversation__server__getAllTeamAndOrgConvosForAccount.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
