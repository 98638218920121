import {
  __ConversationTeam,
  CONVERSATION_TYPES,
  TEAM_PERMISSIONS,
  TEAM_CONVERSATION_TYPES,
  __ConversationOrg,
  ORG_CONVERSATION_TYPES,
  ConversationId,
  ConversationCustomOrgFilters,
  OrgId,
  ORG_CONVERSATION_ICONS,
  OrgTeamTag,
  TeamId
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { hasPermissionOnTeam } from "../../compute/teamLegacy.compute";
import _ from "lodash";
import { isOrgAdmin } from "../../compute";
import { ObjectKeys } from "../../utils";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { compute } from "../..";

export async function conversation__server__updateTeamConversation(p: {
  selfAccountId: string;
  conversationId: ConversationId;
  teamId: TeamId;
  orgId?: OrgId;
  disableNonStaffResponses?: boolean;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [team, org] = await Promise.all([h.Team.getDoc(p.teamId), p.orgId ? h.Org.getDoc(p.orgId) : null]);
  if (!team) {
    throw new Error("Can't find the team");
  }

  const isTeamStaff = compute.isStaff({
    team,
    accountId: p.selfAccountId
  });

  const isSelfOrgAdmin = !org || compute.isOrgAdmin([org], p.selfAccountId, org.id);

  if (!isTeamStaff && !isSelfOrgAdmin) {
    throw new Error("User does not have permission to edit this conversation");
  }

  await h.Conversation.update({
    id: p.conversationId,
    doc: { disableNonStaffResponses: p.disableNonStaffResponses }
  });

  // SERVER_ONLY_TOGGLE
}

conversation__server__updateTeamConversation.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
