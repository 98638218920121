import { getServerHelpers } from "../../helpers";
import * as express from "express";
import { QueryResult, SearchResult } from "@ollie-sports/models";

export async function conversation__server__searchAvailableContacts(p: {
  selfAccountId: string;
  query: string;
  counter?: number;
}): Promise<SearchResult[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();
  let result: SearchResult[] = [];

  // Since we tokenize on each word SQL injection should not be a threat
  let queryLikes = p.query
    .split(" ")
    .filter(l => l.length > 1)
    .map(l => l.trim().toLowerCase())
    .map(l => `f.index like '% ${l}%'`);

  if (queryLikes.length === 0) {
    return result;
  }

  let queryLikesString = queryLikes.join(" AND ");

  let q = `
select distinct a.id                 as account_id,
                a.item->>'firstName' as account_firstName,
                a.item->>'lastName'  as account_lastName,
                a.item->>'email'     as account_email,
                f.team_name,
                a.item->>'profileImageUriSmall' as profile_photo_uri
from (select lower(
               CONCAT(' ', a.item->>'firstName', ' ', a.item->>'lastName', ' ', a.item->>'email', ' ', teamsUserCanSee.team_name)) as index,
             a.id                                                                                           as account_id,
             teamsUserCanSee.team_name                                                                      as team_name
      from (select id team_id,  jsonb_object_keys( item -> 'accounts' ) account_id from mirror_team) ta,
           (select t.id as team_id, t.item->>'name' as team_name
            from mirror_team t
            where t.item->'accounts' @> '{ "${p.selfAccountId}": {} }'
            union
            select t.id as team_id, t.item->>'name' as team_name
            from (select t.item->>'orgId' as orgId
                  from mirror_team t
                  where t.item->'accounts' @> '{ "${p.selfAccountId}": {} }'
                    AND t.item->'orgId' is not null
                  union
                  select o.id as orgId
                  from mirror_org o
                  where o.item->'accounts' @> '{ "${p.selfAccountId}": {} }') orgsUserApartOf,
                 mirror_team t
            where orgsUserApartOf.orgId = t.item->>'orgId' and t.item->>'deletedAtMS' = '0') teamsUserCanSee,
           mirror_account a
      where ta.team_id = teamsUserCanSee.team_id
        and a.id = ta.account_id
      union
      select distinct lower(CONCAT(' ', a.item->>'firstName', ' ', a.item->>'lastName', ' ', a.item->>'email')) as index,
                      a.id                                                               as account_id,
                      ''                                                                 as team_name
      from (select t.item->>'orgId' as orgId
            from mirror_team t
            where t.item->'accounts' @> '{ "${p.selfAccountId}": {} }'
              AND t.item->'orgId' is not null
            union
            select o.id as orgId from mirror_org o where o.item->'accounts' @> '{ "${p.selfAccountId}": {} }') orgsUserApartOf,
           (select id org_id,  jsonb_object_keys( item -> 'accounts' ) account_id from mirror_org) oa,
           mirror_account a
      where oa.org_id = orgsUserApartOf.orgId
        and a.id = oa.account_id) as f,
     mirror_account a
where a.id = f.account_id
and ${queryLikesString};
  `;

  const queryResults: QueryResult[] = (await getAppPgPool().query(q)).rows;

  const infoByAccoundId: Record<string, SearchResult> = {};
  queryResults.forEach(contact => {
    const entry = infoByAccoundId[contact.account_id];
    if (!entry) {
      infoByAccoundId[contact.account_id] = {
        account_id: contact.account_id,
        full_name: contact.account_firstname + " " + contact.account_lastname,
        initials: `${contact.account_firstname[0]}${contact.account_lastname[0]}`,
        team_names: [contact.team_name],
        profile_photo_uri: contact.profile_photo_uri,
        account_email: contact.account_email,
        account_firstname: contact.account_firstname,
        account_lastname: contact.account_lastname
      };
    } else {
      entry.team_names.push(contact.team_name);
    }
  });

  return Object.values(infoByAccoundId);
  // SERVER_ONLY_TOGGLE
}
conversation__server__searchAvailableContacts.auth = (req: express.Request) => {
  // Make sure token in valid
  // Make sure req.body.selfAccountId matches token.uid
};

// i18n certified - complete
