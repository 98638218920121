import {
  Conversation,
  CONVERSATION_TYPES,
  ORG_CONVERSATION_TYPES,
  Team,
  TEAM_CONVERSATION_TYPES,
  TEAM_ROLES
} from "@ollie-sports/models";
import { getListOfAccountIdsForOrgChatChannel } from "../../compute/org.compute";
import { extactSquadKey, extactSquadSubset, isSquadConversation } from "../../compute/conversation.compute";
import { getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { ObjectKeys } from "../../utils";
import { compute } from "../..";

//NOTE: Returns empty array for conversation of type ORG_CONVERSATION_TYPES.all
export async function conversation__server__accountIds(p: {
  conversationId: string;
  selfAccountId: string;
  typesToExclude?: CONVERSATION_TYPES[];
}): Promise<string[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const conversation = await h.Conversation.getDoc(p.conversationId);

  if (!conversation) {
    throw new Error("Conversation not found");
  }

  if (p.typesToExclude?.includes(conversation.conversationType)) {
    return [];
  }

  if (conversation.conversationType === CONVERSATION_TYPES.accounts) {
    return Object.keys(conversation.accounts);
  } else if (conversation.conversationType === CONVERSATION_TYPES.team) {
    const team = await h.Team.getDoc(conversation.teamId);
    if (!team) {
      throw new Error("Invalid team conversation!");
    }

    const isSquadConvo = isSquadConversation(conversation);

    if (isSquadConvo) {
      return await fetchAccountIdsOnSquad({
        team,
        squad: extactSquadKey(conversation.teamConversationType),
        squadSubset: extactSquadSubset(conversation.teamConversationType)
      });
    } else {
      const rolesPerType: { [type in TEAM_CONVERSATION_TYPES]?: TEAM_ROLES[] } = {
        [TEAM_CONVERSATION_TYPES.team]: [TEAM_ROLES.staff, TEAM_ROLES.athlete],
        [TEAM_CONVERSATION_TYPES.staff]: [TEAM_ROLES.staff],
        [TEAM_CONVERSATION_TYPES.staff_guardians]: [TEAM_ROLES.staff, TEAM_ROLES.guardian],
        [TEAM_CONVERSATION_TYPES.players]: [TEAM_ROLES.athlete],
        [TEAM_CONVERSATION_TYPES.all]: [TEAM_ROLES.staff, TEAM_ROLES.athlete, TEAM_ROLES.guardian],
        [TEAM_CONVERSATION_TYPES.alerts]: [TEAM_ROLES.staff, TEAM_ROLES.athlete, TEAM_ROLES.guardian]
      };

      const roles = rolesPerType[conversation.teamConversationType] || [];

      return Object.keys(team.accounts).filter(accountId => {
        return roles.some(role => team.accounts[accountId]?.roles?.[role]);
      });
    }
  } else {
    if (!compute.conversation.isOrgAllConversation(conversation)) {
      const [orgTeamsD, org] = await Promise.all([
        h.Team.query({ where: [{ orgId: ["==", conversation.orgId] }] }),
        h.Org.getDoc(conversation.orgId)
      ]);

      const orgTeams = orgTeamsD ? orgTeamsD.docs.filter((a): a is Team => !!a) : [];

      if (!org) {
        return [];
      } else {
        return getListOfAccountIdsForOrgChatChannel({
          orgFilters: conversation.orgFilters,
          org,
          orgTeams
        });
      }
    } else {
      return [];
    }
  }
  // SERVER_ONLY_TOGGLE
}

conversation__server__accountIds.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
