import { AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";

export async function conversation__server__blockOrUnblockAccountInTeamOrOrgConversation(p: {
  conversationId: string;
  accountId: AccountId;
  block: boolean;
  blockedByAccountId: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Conversation.update({
    id: p.conversationId,
    doc: {
      blockedAccountIds: {
        [p.accountId]: p.block
          ? {
              blockedAtMS: Date.now(),
              blockedByAccountId: p.blockedByAccountId
            }
          : h._MagicDeleteValue
      }
    }
  });
  // SERVER_ONLY_TOGGLE
}

conversation__server__blockOrUnblockAccountInTeamOrOrgConversation.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
