import _ from "lodash";
import * as express from "express";

import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import { AccountId, CONVERSATION_TYPES, ConversationId } from "@ollie-sports/models";

export async function conversation__server__setTitle(p: { conversationId: ConversationId; title: string; accountId: AccountId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const convo = await h.Conversation.getDoc(p.conversationId);
  const org = await (convo?.conversationType == CONVERSATION_TYPES.org ? h.Org.getDoc(convo.orgId) : null);

  if (
    !convo ||
    convo.conversationType === CONVERSATION_TYPES.team ||
    (convo.conversationType === CONVERSATION_TYPES.accounts && !convo.accounts[p.accountId]) ||
    (convo.conversationType === CONVERSATION_TYPES.org && (!org || !org.accounts[p.accountId]))
  ) {
    throw new Error("Tried to modify inaccessible conversation title!");
  }

  if (convo.conversationType === CONVERSATION_TYPES.org && !p.title.trim().length) {
    throw new Error("Must provide a title for org conversations!");
  }

  const batchTasks: BatchTask[] = [];
  const title = p.title.trim();

  const newMessageId = h.Message.generateId();

  batchTasks.push(
    await h.Conversation.update(
      {
        id: p.conversationId,
        doc: {
          title: title || h._MagicDeleteValue,
          updatedAtMS: Date.now(),
          lastMessageId: newMessageId
        }
      },
      { returnBatchTask: true }
    )
  );

  const account = await h.Account.getDoc(p.accountId);

  batchTasks.push(
    await h.Message.add(
      {
        doc: {
          accountId: p.accountId,
          conversationId: p.conversationId,
          createdAtServerTimestamp: h._MagicServerTimestamp,
          createdAtMS: Date.now(),
          id: newMessageId,
          automatedMessageDetails: {
            type: "conversationTitleChange",
            accountName: account?.firstName ?? "",
            newConversationName: title
          },
          deletedAtMS: 0
        }
      },
      { returnBatchTask: true }
    )
  );

  await h._BatchRunner.executeBatch(batchTasks);
  // SERVER_ONLY_TOGGLE
}

conversation__server__setTitle.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
