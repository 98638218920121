import { getUniversalHelpers } from "../../helpers";
import { CONVERSATION_TYPES, Conversation, __ConversationAccount } from "@ollie-sports/models";
import _ from "lodash";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function conversation__server__getConversationWithExactAccountIds(p: {
  accountIds: string[];
}): Promise<Conversation | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const docs = await h.Conversation.query({
    where: p.accountIds.map(id => {
      return { accounts: { [id]: ["==", true] } };
    })
  });

  return (
    docs.docs.find(
      a => a.conversationType === CONVERSATION_TYPES.accounts && _.difference(Object.keys(a.accounts), p.accountIds).length === 0
    ) || null
  );
}

conversation__server__getConversationWithExactAccountIds.auth = async (req: any) => {
  const { uid } = await validateToken(req);
  if (!req.body.accountIds.includes(uid)) {
    throw new Error("Can only check own account conversations for existence!");
  }
};

// i18n certified - complete
