import { AccountId, ConversationId } from "@ollie-sports/models";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { conversation__server__accountIds } from "./conversation__accountIds";

export async function conversation__server__notifyParticipantsOfConversationUpdate(p: {
  conversationId: ConversationId;
  selfAccountId: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountIds = await conversation__server__accountIds(p);

  const now = Date.now();

  await Promise.all(
    accountIds.map(id =>
      h.AccountPrivate.update({
        id,
        doc: {
          settings: {
            participatingConversationUpdatedAtMS: now,
            mostRecentParticipatingConversationUpdatedId: p.conversationId
          }
        }
      })
    )
  );

  // SERVER_ONLY_TOGGLE
}

conversation__server__notifyParticipantsOfConversationUpdate.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
