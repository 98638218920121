import {
  __ConversationTeam,
  CONVERSATION_TYPES,
  TEAM_PERMISSIONS,
  TEAM_CONVERSATION_TYPES,
  __ConversationOrg,
  ORG_CONVERSATION_TYPES,
  OrgTeamTag
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { hasPermissionOnTeam } from "../../compute/teamLegacy.compute";
import _ from "lodash";
import { getListOfAccountIdsForOrgChatChannel, isOrgAdmin } from "../../compute";
import { ObjectKeys } from "../../utils";
import { SimpleQuery } from "@ollie-sports/firebase-lift";

export async function conversation__server__createOrgConversation(p: {
  selfAccountId: string;
  conversation: Pick<__ConversationOrg, "orgId" | "orgFilters" | "title" | "icon" | "disableNonOrgAdminResponses">;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  // make sure conversation doesn't exist
  const convos = (
    await h.Conversation.query({
      where: [
        {
          conversationType: ["==", CONVERSATION_TYPES.org]
        },
        {
          orgId: ["==", p.conversation.orgId]
        }
      ]
    })
  ).docs;

  if (convos.length > 0) {
    const matchingConvo = convos.find(
      convo => convo.conversationType === CONVERSATION_TYPES.org && _.isEqual(convo.orgFilters, p.conversation.orgFilters)
    );
    if (matchingConvo) {
      return matchingConvo.id;
    }
  }

  // make sure person has permission
  const org = await h.Org.getDoc(p.conversation.orgId);
  if (!org) {
    throw new Error("Org does not exists");
  }

  if (!isOrgAdmin([org], p.selfAccountId, p.conversation.orgId)) {
    throw { statusCode: 401, error: Error("Insufficient org permissions") };
  }

  // generate the new id and conversation
  const newId = h.Conversation.generateId();
  const now = Date.now();

  await h.Conversation.add({
    doc: {
      conversationType: CONVERSATION_TYPES.org,
      createdAtMS: now,
      updatedAtMS: now,
      id: newId,
      ...p.conversation
    }
  });

  return newId;
  // SERVER_ONLY_TOGGLE
}

conversation__server__createOrgConversation.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
