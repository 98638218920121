import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { diff } from "deep-diff";
import {
  Conversation,
  ConversationId,
  CONVERSATION_TYPES,
  Org,
  OrgId,
  ORG_CONVERSATION_TYPES,
  PlayerBundleId,
  PlayerBundle__AccountType,
  PrettyPlayerBundle,
  Team,
  TeamId,
  TEAM_CONVERSATION_TYPES,
  __ConversationOrg,
  __ConversationTeam,
  __ConversationAccount,
  Account,
  Message,
  RealTimeNotification,
  NotificationType
} from "@ollie-sports/models";
import { getListOfAccountIdsForOrgChatChannel } from "../../compute";
import {
  isSquadConversation,
  canAccessSquadConversation,
  getConversationTitle,
  getLastMessageText,
  computeOneOnOneAccountIconInfo
} from "../../compute/conversation.compute";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import _ from "lodash";
import { ConversationSimple, RTBDRefs } from "../../constants";
import { translate } from "@ollie-sports/i18n";
import { fetchAllConversationsForUser } from "./conversationMisc";

export async function conversation__server__getAllConversationsForAccount(p: {
  selfAccountId: string;
  teamIds: TeamId[];
  orgIds: OrgId[];
  playerIdMap: { playerId: string; type: PlayerBundle__AccountType }[];
  limit: number;
  locale: string;
  afterConversationId?: string;
  //Leave out the `accounts` property b/c it's too heavy and is only needed for backend stuff like populating the title
  //We also always populate title
}): Promise<ConversationSimple[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let {
    conversations: absolutelyAllUserConvos,
    userOrgs,
    userTeams
  } = await fetchAllConversationsForUser({ ...p, includeArchived: false });

  let relevantConvos = absolutelyAllUserConvos.slice();

  if (p.afterConversationId) {
    const index = relevantConvos.findIndex(a => a.id === p.afterConversationId);
    relevantConvos = index !== -1 ? relevantConvos.slice(index + 1) : [];
  }

  relevantConvos = relevantConvos.slice(0, p.limit);

  //Only fetch up to 3 people on account conversations to populate title b/c that's all that can fit in the title
  const relevantAccountIdsMap: Record<string, true> = {};
  relevantConvos.forEach(c => {
    if ("accounts" in c && !c.title) {
      Object.keys(c.accounts)
        .filter(id => id !== p.selfAccountId)
        .sort()
        .slice(0, 3)
        .forEach(accId => {
          relevantAccountIdsMap[accId] = true;
        });
    }
  });

  // As a side effect of fetching, clean up any realtime notifications for conversations the user is no longer part of
  const cleanupAbandonedNotificationsPromise = RTBDRefs.getAccountSimpleNotificationRef({ accountId: p.selfAccountId })
    .get()
    .then(a => (a.val() || {}) as Record<string, RealTimeNotification>)
    .then(async notifications => {
      const notificationIdsByConvoId: Record<string, string[]> = {};
      Object.values(notifications).forEach(n => {
        if (n.t === NotificationType.chatMessage) {
          notificationIdsByConvoId[n.cId] = notificationIdsByConvoId[n.cId] || [];
          notificationIdsByConvoId[n.cId].push(n.id);
        }
      });

      const allConvos: Record<string, true> = {};
      absolutelyAllUserConvos.forEach(c => {
        allConvos[c.id] = true;
      });

      const notificationIdsToScrub = _(notificationIdsByConvoId)
        .mapValues((notificationIds, cid) => {
          if (!allConvos[cid]) {
            return notificationIds;
          } else {
            return [];
          }
        })
        .values()
        .uniq()
        .flatten()
        .value();

      await Promise.all(
        notificationIdsToScrub.map(async nid => {
          try {
            await RTBDRefs.getAccountSimpleNotificationDeepRef({ accountId: p.selfAccountId, notificationId: nid }).remove();
          } catch (e) {
            console.error("Problem scrubbing " + nid);
            console.error(e);
          }
        })
      );
    })
    .catch(e => {
      console.error("Problem cleaning up abandoned notifications");
      console.error(e);
    });

  const [accounts, lastMessages] = await Promise.all([
    _.compact(await h.Account.getDocs(Object.keys(relevantAccountIdsMap))),
    _.compact(await h.Message.getDocs(_.compact(absolutelyAllUserConvos.map(a => a.lastMessageId))))
  ]);

  const accountsMap = accounts.reduce((acc, a) => {
    acc[a.id] = a;
    return acc;
  }, {} as { [str in string]?: Account });

  const lastMessagesMap = lastMessages.reduce((acc, a) => {
    acc[a.id] = a;
    return acc;
  }, {} as { [str in string]?: Message });

  const convos = await Promise.all(
    relevantConvos.map(async convo => {
      const theseAccounts =
        convo.conversationType === CONVERSATION_TYPES.accounts
          ? _(Object.keys(convo.accounts))
              .map(a => accountsMap[a])
              .compact()
              .value()
          : [];

      const derivedTitle = getConversationTitle({
        accounts: theseAccounts,
        selfAccountId: p.selfAccountId,
        maxAccountsInTitle: 3,
        conversation: convo,
        userOrgs: userOrgs,
        userTeams: userTeams,
        locale: p.locale
      });

      const lastMessage = lastMessagesMap[convo.lastMessageId || ""];

      const lastMessageText = lastMessage
        ? getLastMessageText({
            lastMessage,
            lastMessageAccountFirstName: accountsMap[lastMessage.accountId || ""]?.firstName || "",
            locale: p.locale
          })
        : "";

      return {
        ..._.omit(convo as __ConversationAccount, "accounts", "title"),
        derivedTitle,
        lastMessageText,
        numAccountIds: "accounts" in convo ? Object.keys(convo.accounts).length : undefined,
        oneOnOneAccountIconInfo: computeOneOnOneAccountIconInfo(theseAccounts)
      };
    })
  );

  await cleanupAbandonedNotificationsPromise;

  return convos;

  // SERVER_ONLY_TOGGLE
}

conversation__server__getAllConversationsForAccount.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
