import { getUniversalHelpers } from "../../helpers";
import { Conversation } from "@ollie-sports/models";

export async function conversation__client__getConversation(p: { conversationId: string }): Promise<Conversation | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const conversation = await h.Conversation.getDoc(p.conversationId);

  return conversation;
}

// i18n certified - complete
