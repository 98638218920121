import { Account, CONVERSATION_TYPES, TEAM_CONVERSATION_TYPES } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { extactSquadSubset, extactSquadKey } from "../../compute/conversation.compute";

export async function conversation__client__fetchSquadConversationParticipants(p: {
  conversationId: string;
  accountId: string;
}): Promise<Account[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const conversation = await h.Conversation.getDoc(p.conversationId);
  if (!conversation) {
    throw new Error("Conversation not found");
  }

  if (conversation.conversationType !== CONVERSATION_TYPES.team) {
    throw new Error("Squad conversations must be of team type");
  }

  const team = await h.Team.getDoc(conversation.teamId);
  if (!team) {
    throw new Error("Unable to find team");
  }

  let accountIds = await fetchAccountIdsOnSquad({
    team,
    squad: extactSquadKey(conversation.teamConversationType),
    squadSubset: extactSquadSubset(conversation.teamConversationType)
  });

  const accountsTemp = await h.Account.getDocs(accountIds);
  const accounts: Account[] = [];
  accountsTemp.forEach(a => {
    if (a) {
      accounts.push(a);
    }
  });

  return accounts;
}

// i18n certified - complete
