import { AccountId, Conversation } from "@ollie-sports/models";
import { getAccountConversationsForAccountIdFragment } from "../../query-fragments/conversation.fragments";
import { getUniversalHelpers } from "../../helpers";

export async function conversation__client__fetchAccountConversationsForAccountId(p: {
  accountId: AccountId;
}): Promise<Conversation[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let r = await h.Conversation.query(getAccountConversationsForAccountIdFragment({ accountId: p.accountId }));
  return r.docs;
}

// i18n certified - complete
