import { __ConversationTeam, CONVERSATION_TYPES, TEAM_PERMISSIONS, TEAM_CONVERSATION_TYPES } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { hasPermissionOnTeam } from "../../compute/teamLegacy.compute";

export async function conversation__server__createTeamConversation(p: {
  selfAccountId: string;
  conversation: Pick<__ConversationTeam, "teamId" | "teamConversationType">;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  // make sure conversation doesn't exist
  const convos = await h.Conversation.query({
    where: [
      {
        conversationType: ["==", CONVERSATION_TYPES.team]
      },
      {
        teamId: ["==", p.conversation.teamId]
      }
    ]
  });

  if (convos.docs.length > 0) {
    if (p.conversation.teamConversationType) {
      const matchingConvos = convos.docs.filter(
        c => c.conversationType === CONVERSATION_TYPES.team && c.teamConversationType === p.conversation.teamConversationType
      );
      if (matchingConvos.length > 0) {
        return matchingConvos[0].id;
      }
    } else {
      return convos.docs[0].id;
    }
  }

  // make sure person has permission
  const team = await h.Team.getDoc(p.conversation.teamId);
  if (!team) {
    throw new Error("Team does not exists");
  }
  let permissionNeeded = TEAM_PERMISSIONS.manageRolesAndPermissions;
  switch (p.conversation.teamConversationType) {
    case TEAM_CONVERSATION_TYPES.all:
      permissionNeeded = TEAM_PERMISSIONS.participateTeamAllConversations;
      break;
    case TEAM_CONVERSATION_TYPES.alerts:
      permissionNeeded = TEAM_PERMISSIONS.participateTeamAllConversations;
      break;
    case TEAM_CONVERSATION_TYPES.players:
      permissionNeeded = TEAM_PERMISSIONS.participateTeamAthleteConversations;
      break;
    case TEAM_CONVERSATION_TYPES.staff:
      permissionNeeded = TEAM_PERMISSIONS.participateTeamStaffConversations;
      break;
    case TEAM_CONVERSATION_TYPES.team:
      permissionNeeded = TEAM_PERMISSIONS.participateTeamTeamConversations;
      break;
    case TEAM_CONVERSATION_TYPES.staff_guardians:
      permissionNeeded = TEAM_PERMISSIONS.participateTeamStaffGuardiansConversations;
      break;
    case TEAM_CONVERSATION_TYPES.squad_a_all:
    case TEAM_CONVERSATION_TYPES.squad_a_players:
    case TEAM_CONVERSATION_TYPES.squad_a_team:
    case TEAM_CONVERSATION_TYPES.squad_b_all:
    case TEAM_CONVERSATION_TYPES.squad_b_players:
    case TEAM_CONVERSATION_TYPES.squad_b_team:
    case TEAM_CONVERSATION_TYPES.squad_c_all:
    case TEAM_CONVERSATION_TYPES.squad_c_players:
    case TEAM_CONVERSATION_TYPES.squad_c_team:
      permissionNeeded = TEAM_PERMISSIONS.basic;
      break;
  }
  if (!hasPermissionOnTeam({ team, permission: permissionNeeded, accountId: p.selfAccountId })) {
    throw { statusCode: 401, error: Error("Insufficient team permissions") };
  }

  // generate the new id and conversation
  const newId = h.Conversation.generateId();
  const now = Date.now();

  await h.Conversation.add({
    doc: {
      conversationType: CONVERSATION_TYPES.team,
      createdAtMS: now,
      updatedAtMS: now,
      teamConversationType: p.conversation.teamConversationType,
      teamId: p.conversation.teamId,
      id: newId,
      disableNonStaffResponses: p.conversation.teamConversationType === TEAM_CONVERSATION_TYPES.alerts ? true : undefined
    } as __ConversationTeam
  });

  return newId;
  // SERVER_ONLY_TOGGLE
}

conversation__server__createTeamConversation.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
