import { getServerHelpers } from "../../helpers";
import * as express from "express";
import _ from "lodash";
import {
  CONVERSATION_TYPES,
  Conversation,
  ConversationId,
  Message,
  OrgId,
  PlayerBundle__AccountType,
  TeamId
} from "@ollie-sports/models";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { fetchAllConversationsForUser } from "./conversationMisc";
import { conversation__client__getConversationSimple } from "./conversation__getConversationSimple";
import { ConversationSimple } from "../../constants";

export async function conversation__server__searchMessages(p: {
  query: string;
  teamIds: TeamId[];
  orgIds: OrgId[];
  playerIdMap: { playerId: string; type: PlayerBundle__AccountType }[];
  selfAccountId: string;
  locale: string;
}): Promise<{ conversation: ConversationSimple; message?: Message }[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool, appOllieFirestoreV2: h } = getServerHelpers();

  const { conversations, userOrgs, userTeams } = await fetchAllConversationsForUser({ ...p, includeArchived: true });

  const conversationIds = conversations.map(a => a.id);

  const searchItems = p.query
    .split(" ")
    .map(i => i.trim().toLowerCase())
    .filter(i => i.length > 1);

  if (searchItems.length === 0) {
    return [];
  }

  let [messages, convos1, convos2, selfAccount] = await Promise.all([
    //Search message text
    getAppPgPool().query(
      `
SELECT 'message' as type, m.item as message, c.item as conversation, m.rank as rank, updated_at
FROM (
         SELECT item ->> 'conversationId'                                       as conversation_id,
                item,
                ts_rank_cd(textsearchable_index_col, to_tsquery('english', $1)) as rank
         FROM mirror_message
         WHERE textsearchable_index_col @@ to_tsquery('english', $1)
           and item ->> 'conversationId' = any ($2::text[])
           and coalesce((item ->> 'deletedAtMS')::numeric, 0) = 0
         ORDER BY rank DESC, updated_at DESC
         LIMIT 20
     ) m,
     mirror_conversation c
WHERE m.conversation_id = c.id
      `,
      [searchItems.join(" | "), conversationIds]
    ),
    //Search explicit conversation titles (Note: This will only be set on some convos. It is NOT the derived title which is displayed in the UI)
    getAppPgPool().query(
      `SELECT 'conversation' as type, item,
ts_rank_cd(to_tsvector(item->>'title'), to_tsquery('english'::regconfig, $1)) as rank, updated_at
FROM mirror_conversation
WHERE to_tsvector('english'::regconfig, item->>'title') @@ to_tsquery('english'::regconfig, $1)
AND id = any ($2::text[])
ORDER BY rank DESC, updated_at DESC
LIMIT 20;`,
      [searchItems.join(" | "), conversationIds]
    ),
    //Search on participant names
    getAppPgPool().query(
      `SELECT 'conversation' as type, item,
ts_rank_cd(possibly_stale_account_names, to_tsquery('english'::regconfig, $1)) as rank, updated_at
FROM mirror_conversation
WHERE possibly_stale_account_names @@ to_tsquery('english'::regconfig, $1)
AND id = any ($2::text[])
ORDER BY rank DESC, updated_at DESC
LIMIT 20;`,
      [searchItems.join(" | "), conversationIds]
    ),
    h.Account.getDoc(p.selfAccountId)
  ]);

  const ownName = [selfAccount?.firstName, selfAccount?.lastName].filter(Boolean).join(" ").toLowerCase();

  if (searchItems.some(term => ownName.includes(term.toLowerCase()))) {
    const searchRegexArr = searchItems.reduce((acc, searchTerm, i) => {
      if (ownName.includes(searchTerm)) {
        acc.push(`${searchTerm}.*${searchTerm}`); //Require the search term matching the user's name to show up twice for there to be a match
      } else {
        acc.push(searchTerm);
      }
      return acc;
    }, [] as string[]);

    const searchRegex = `(${searchRegexArr.join("|")})`;

    const query = `SELECT 'conversation' as type, item, array_length(regexp_matches(tsvector_to_string(possibly_stale_account_names), $1, 'g'), 1) AS rank, updated_at
    FROM mirror_conversation
    WHERE tsvector_to_string(possibly_stale_account_names) ~ $1
    AND id = any ($2::text[])
    ORDER BY rank DESC
    LIMIT 20;`;

    convos2 = await getAppPgPool().query(query, [searchRegex, conversationIds]);
  }

  const ret = _([...messages.rows, ...convos1.rows, ...convos2.rows])
    .orderBy([a => a.rank, a => a.updated_at], ["desc", "desc"])
    .map(r => {
      if (r.type === "message") {
        return {
          conversation: r.conversation as Conversation,
          message: r.message as Message
        };
      } else {
        return { conversation: r.item as Conversation };
      }
    })
    .value();

  ret.forEach(({ conversation }) => {
    if (conversation.conversationType === CONVERSATION_TYPES.accounts && !conversation.accounts[p.selfAccountId]) {
      throw new Error("Tried to access forbidden account conversations!");
    }
    //TODO: Secure team and org conversations...
  });

  const finalRet = await Promise.all(
    ret.map(async a => {
      const conversation = await conversation__client__getConversationSimple({
        conversationId: a.conversation.id,
        currentUserOrgs: userOrgs,
        currentUserTeams: userTeams,
        locale: p.locale,
        selfAccountId: p.selfAccountId
      });

      return conversation
        ? {
            conversation,
            message: a.message
          }
        : null;
    })
  );

  return _.compact(finalRet);

  // SERVER_ONLY_TOGGLE
}
conversation__server__searchMessages.auth = (req: express.Request) => {
  validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
