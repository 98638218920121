import {
  __ConversationTeam,
  CONVERSATION_TYPES,
  TEAM_PERMISSIONS,
  TEAM_CONVERSATION_TYPES,
  __ConversationOrg,
  ORG_CONVERSATION_TYPES,
  ConversationId,
  ConversationCustomOrgFilters,
  OrgId,
  ORG_CONVERSATION_ICONS,
  OrgTeamTag
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { hasPermissionOnTeam } from "../../compute/teamLegacy.compute";
import _ from "lodash";
import { isOrgAdmin } from "../../compute";
import { ObjectKeys } from "../../utils";
import { SimpleQuery } from "@ollie-sports/firebase-lift";

export async function conversation__server__updateOrgConversation(p: {
  selfAccountId: string;
  conversationId: ConversationId;
  orgId: OrgId;
  newOrgFilters: ConversationCustomOrgFilters;
  newTitle: string;
  newIcon: ORG_CONVERSATION_ICONS;
  disableNonOrgAdminResponses?: boolean;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [conversationResults, org] = await Promise.all([
    h.Conversation.query({
      where: [
        {
          id: ["==", p.conversationId]
        }
      ]
    }),
    h.Org.getDoc(p.orgId)
  ]);

  const conversation = conversationResults.docs.length ? conversationResults.docs[0] : undefined;

  if (!conversation) {
    throw new Error("Cannot find the conversation");
  }
  if (!org) {
    throw new Error("Cannot find the org");
  }
  if (conversation.conversationType !== CONVERSATION_TYPES.org) {
    throw new Error("The conversation is not an org conversation");
  }
  if (!org.accounts[p.selfAccountId]) {
    throw new Error("This account cannot update the org conversation because they are not an org admin");
  }

  await h.Conversation.setPath({
    id: p.conversationId,
    pathObj: { orgFilters: true },
    value: { orgFilters: p.newOrgFilters }
  });

  if (
    conversation.title !== p.newTitle ||
    conversation.icon !== p.newIcon ||
    conversation.disableNonOrgAdminResponses !== p.disableNonOrgAdminResponses
  ) {
    await h.Conversation.update({
      id: p.conversationId,
      doc: { icon: p.newIcon, title: p.newTitle, disableNonOrgAdminResponses: p.disableNonOrgAdminResponses }
    });
  }

  // SERVER_ONLY_TOGGLE
}

conversation__server__updateOrgConversation.auth = async (r: express.Request) => {
  await validateToken(r);
  // make sure valid token
  // make sure selfAccountId matches request.body.selfAccountId
};

// i18n certified - complete
