import _ from "lodash";
import { ConversationSimple } from "../../constants";
import { getUniversalHelpers } from "../../helpers";
import { Conversation, Org, Team, __ConversationAccount } from "@ollie-sports/models";
import { computeOneOnOneAccountIconInfo, getConversationTitle, getLastMessageText } from "../../compute/conversation.compute";

export async function conversation__client__getConversationSimple(p: {
  conversationId: string;
  locale: string;
  selfAccountId: string;
  currentUserOrgs: Org[];
  currentUserTeams: Team[];
}): Promise<ConversationSimple | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const conversation = await h.Conversation.getDoc(p.conversationId);

  if (!conversation) {
    return null;
  }

  const [accountsRaw, lastMessage] = await Promise.all([
    "accounts" in conversation ? h.Account.getDocs(Object.keys(conversation.accounts)) : null,
    conversation.lastMessageId ? h.Message.getDoc(conversation.lastMessageId) : null
  ]);

  const accounts = accountsRaw ? _.compact(accountsRaw) : [];

  const conversationSimple: ConversationSimple = {
    ..._.omit(conversation as __ConversationAccount, "accounts", "title"),
    derivedTitle: getConversationTitle({
      conversation,
      accounts,
      selfAccountId: p.selfAccountId,
      maxAccountsInTitle: 3,
      userOrgs: p.currentUserOrgs,
      userTeams: p.currentUserTeams,
      locale: p.locale
    }),
    lastMessageText: lastMessage
      ? getLastMessageText({
          lastMessage,
          lastMessageAccountFirstName: accounts?.find(b => b?.id === lastMessage.accountId)?.firstName || "",
          locale: p.locale
        })
      : "",
    numAccountIds: "accounts" in conversation ? Object.keys(conversation.accounts).length : undefined,
    oneOnOneAccountIconInfo: computeOneOnOneAccountIconInfo(accounts)
  };

  return conversationSimple;
}

// i18n certified - complete
